
.Color-Cell-Content {
    height: 16px;
    line-height: 1.14;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Color-Cell {
    height: 17px;
    border-radius: 2px;
    white-space: nowrap;
    vertical-align: middle;
}


.Color-Cell-Wrap {
    height: 17px;
    border-radius: 2px;
    vertical-align: top !important;
}

.Color-Cell-Wrap .Color-Cell-Content {
    height: 16px;
    line-height: 1.14;
    letter-spacing: normal;
    text-overflow: ellipsis;
    overflow: visible;
}