.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(43, 45, 66, 0.5);
  z-index: 1001;
}

.Modal {
  width: 400px;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
}

.Modal-Cancel {
  color: #009bde;
  margin: 8px 0;
  font-size: 14px;
}

.Modal-Header {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  border-bottom: 1px solid #e2e5ea;
  padding: 24px 24px 20px 24px;
}

.Modal-Title-temp {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.Modal-Title-Text {
  color: #2b2d42;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 36px;
}

.Modal-SubTitle {
  padding-top: 18px;
}

.Modal-SubTitle-Text {
  color: #8d99ae;
  font-size: 11px;
  line-height: 12px;
}

.Modal-Actions {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e5ea;
  padding: 24px 24px 27px 24px;
}

.Modal-Close {
  margin: 3px;
  align-self: flex-start;
}

.Modal-Content {
  padding: 0 24px;
  max-height: 720px;
  overflow-x: auto;
  color: #555667;
}

/* TODO: scroll styling only works in chrome/safari */
.Modal-Content::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: white;
}

.Modal-Content::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

.Modal-Content::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8d99ae;
}
