.Dropdown-Item {
    background-color: white;
    padding: 8px;
}

.Dropdown-Item-Button {
    all: inherit;
    background: white;
    border: none;
}

.Dropdown-Item:hover {
    cursor: pointer;
}