ul {
    padding: 0;
    margin: 0;
}

.Dropdown-Container {
    display: flex;
    width: 100%;
    flex-direction: column;
    outline: none;
    cursor: pointer;
}

.Dropdown-Header,
.Dropdown-Header-Selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    height: 44px;
    margin-right: 4px;
    text-align: center;
}


.Dropdown-Error {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}

.Dropdown-Text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #555667;
    font-size: 14px;
    height: 16px;
    margin-left: 12px;
    user-select: none;
}

.Dropdown-Icon {
    display: flex;
    align-self: center;
    margin-right: 12px;
    width: 15px;
    height: 8px;
}

.Dropdown-Drawer {
    display: none;
}

.Dropdown-Drawer-Show {
    z-index: 300;
}

.Dropdown-Items-Container {
    position: absolute;
    width: 100%;
    -ms-flex-direction: column;
    background-color: white;
    border: 1px solid #E2E5EA;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    flex-direction: column;
    max-height: 280px;
    overflow-y: auto;
    z-index: 2000;
}

.Dropdown-AutoPosition-Items-Container {
    width: 100%;
    -ms-flex-direction: column;
    background-color: white;
    border: 1px solid #E2E5EA;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    flex-direction: column;
    overflow-y: auto;
}

.Dropdown-Item {
    display: flex;
    -ms-flex-direction: row;
    flex: 1 0;
    -ms-flex: 1 0;
    color: #555667;
    display: -ms-flexbox;
    font-size: 14px;
    line-height: 16px;
}

.Dropdown-Item:hover {
    display: flex;
    -ms-flex-direction: row;
    color: #555667;
    display: -ms-flexbox;
    font-size: 14px;
    line-height: 16px;
    background-color: #A8DAEF;
}

.Dropdown-Disabled {
    border: 1px solid #e2e5ea;
    cursor: initial;
}

.Dropdown-Disabled .Dropdown-Header,
.Dropdown-Disabled .Dropdown-Header-Selected {
    background-color: #f2f5f7;
}

.Dropdown-Disabled .Dropdown-Text {
    color: #e2e5ea;
}