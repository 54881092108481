.Cell-Link {
  color: #009bde;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.Cell-Link-Wrap {
  color: #009bde;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  vertical-align: top !important;
  line-height: 1.25;
}

.Cell-Link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Cell-Link-Wrap:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Cell-Link-Multiple-Hyperlinks {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.Cell-Link-Wrap-Multiple-Hyperlinks {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  vertical-align: top !important;
  line-height: 1.25;
}

.Hyperlink {
  color: #009bde !important;
}

.Hyperlink:hover {
  cursor: pointer;
  text-decoration: underline !important;
}