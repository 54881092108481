.Action-Header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  padding-right: 35px;
  background-color: white;
  border-left: 1px solid #e2e5ea;
  border-top: 1px solid #e2e5ea;
  box-shadow: inset -1px 0 0 0 #F2F5F7, inset 0 -1px 0 0 #E2E5EA;
  z-index: 2;
  right: 0;
  left: 220px;
}

.Overflow-Button {
  margin-left: 6px;
  padding-bottom: 10px;
  background-color: #e2e5ea;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: none;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  color: #199DDB;
  cursor: pointer;
}

.Overflow-Hidden .Overflow-Button:hover {
  background-color: #F2F5F7;
  border-radius: 4px;
}

.Overflow-Hidden .Overflow-Button:active {
  background-color: #E9EBEF;
  border-radius: 4px;
}

.Overflow-Hidden .Overflow-Button {
  background: none;
}

.Overflow-Container {
  position: relative;
  top: 36px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 8px 8px 5px 0px #E9EBEF;
  border: solid 1px #e2e5ea;
}

.Overflow-Hidden .Overflow-Container {
  display: none;
}
