.TimePickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TimeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 198px !important;
    margin-right: 32px;
    font-size: 18px;
}

.TimeContainer:focus-within {
    border: 1px solid #009bde;
    border-radius: 4px;
    outline: none;
}

.TimeContainer .react-time-picker__wrapper {
    border: none;
}

.TimeContainer .react-time-picker__inputGroup {
    align-items: center;
    padding: 0px;
    width: 128px;
    min-width: initial;
}

.TimeContainer .react-time-picker__inputGroup__input {
    min-width: 26px !important;
}

.TimeContainer .react-time-picker__inputGroup__hour {
    text-align: right;
}

.TimeContainer .react-time-picker__inputGroup__input--hasLeadingZero{
    margin-left: -11px;
} 

.TimeContainer .react-time-picker__inputGroup__input::placeholder {
    color: #c6ccd6;
    opacity: 1;
}

.TimeContainer .react-time-picker__inputGroup__input:-ms-input-placeholder {
    color: #c6ccd6;
}

.TimeContainer .react-time-picker__inputGroup__divider {
    color: #555667;
}

.TimeContainer .react-time-picker__inputGroup__leadingZero {
    line-height: normal;
    color: #555667;
    margin-left: 2px;
}

.TimePickerClearIcon, .TimePickerClockIcon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TimePickerClockIcon {
    padding-right: 6px;
}

.Time-Picker-Disabled {
    border: 1px solid #e2e5ea;
    background-color: #f2f5f7;
}

.Time-Picker-Disabled-Value {
    color: #e2e5ea;
}