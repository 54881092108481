.ChooseListItemContainer {
  width: 100%;
  background-color: white;
  border: 1px solid #E2E5EA;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}

.ChooseListItem,
.NoResultsItem {
  display: flex;
  padding: 15px;
  cursor: pointer;
}

.NoResultsItem {
  cursor: default;
}

.ChooseListItem:hover {
  background-color: #A8DAEF;
  color: #555667;
}

.ChooseListItem + .ChooseListItem {
  border-top: 1px solid #E2E5EA;
}

.ChooseListItem img {
  height: 20px;
}

.ChooseListItem span,
.NoResultsItem span {
  font-size: 18px;
  margin-left: 15px;
}