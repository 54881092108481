.Column {
  background-color: #ffffff;
}

.Column-Header {
  padding: 0px 16px;
  border-top: 1px solid #e2e5ea;
  box-shadow: inset -1px 0 0 0 #F2F5F7, inset 0 -2px 0 0 #E2E5EA;
  height: 40px;
  display: flex;
  align-items: center;
}

.Column-Header-No-Hover {
  padding: 0px 16px;
  border-top: 1px solid #e2e5ea;
  box-shadow: inset -1px 0 0 0 #F2F5F7, inset 0 -2px 0 0 #E2E5EA;
  height: 40px;
  display: flex;
  align-items: center;
}

.Column-Header:hover {
  background-color: #EBEDF0;
  box-shadow: inset -1px 0 0 0 #F2F5F7, inset 0 -2px 0 0 #C6CCD6, 0 1px 4px 0 rgba(0,0,0,0.05);
}

.Column:first-child {
  border-left: 1px solid #e2e5ea;
}

.Column:first-child .Selected-Row {
  border-left: solid 1px #189CDB;
  padding-left: 11px;
}

.Column:last-child .Selected-Row {
  border-right: solid 1px #189CDB;
  padding-right: 11px;
}

.Column-Title {
  color: #2b2d42;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  max-width: 200px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
