.Default-Checkbox {
  border: none;
  background-color: transparent;
  padding: 0;
}

.Default-Checkbox:focus {
  outline: none;
}

.Default-Checkbox-Label {
  position: relative;
  top: -3px;
  padding-left: 8px;
  font-size: 18px;
  cursor: pointer;  
}

.Checkbox {
  border-radius: 2px;
}


.Checked {  
  object-fit: contain;
  border-radius: 4px;
}

.Disabled-Checkbox-Label {
  color: #C6CCD6;
  cursor: initial;  
}
