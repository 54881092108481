.Default-Radio-Label {
  position: relative;
  top: -3px;
  padding-left: 8px;
  font-size: 14px;
  cursor: pointer;
}

.Disabled-Radio-Label {
  color: #C6CCD6;
  cursor: initial;
}