.Filter-Dropdown-Text-Search-Container {
  cursor: pointer;
  outline: none;
  position: relative;
  padding-right: 6px;
  /* max-width: 140px; */
}

.Filter-Dropdown-Text-Search-Header,
.Filter-Dropdown-Text-Search-Header-Selected {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-right: 4px;
  min-width: 140px;
  width: 300px;
  text-align: left;
}

.Filter-Dropdown-Text-Search-Header {
  border: 1px solid #e2e5ea;
}

.Filter-Dropdown-Text-Search-Header:hover {  
  border: solid 1px #8d99ae;
}

.Filter-Dropdown-Text-Search-Header-Selected {
  border: 1px solid #009bde;
}

.Filter-Dropdown-Text-Search-Text {
  -ms-user-select: none;
  color: #555667;
  font-size: 14px;
  margin-left: 12px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.Filter-Dropdown-Text-Search-Icon {
  margin: 0px 12px;
  user-select: none;
}

.Filter-Dropdown-Text-Search-Drawer {
  outline: none;
}

.Filter-Dropdown-Text-Search-Text-Input:focus {
  border: 1px solid #009bde;
  outline: none;
}

.Filter-Dropdown-Text-Search-Text-Input::placeholder {
  color: #c6ccd6;
  opacity: 1;
}

.Filter-Dropdown-Text-Search-Text-Input:-ms-input-placeholder {
  color: #c6ccd6;
}

.Filter-Dropdown-Text-Search-Text-Input:disabled {
  border: 1px solid #e2e5ea;
  color: #e2e5ea;
  background-color: #f2f5f7;
}

.Filter-Dropdown-Text-Search-Text-Input-Container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.Filter-Dropdown-Text-Search-Text-Input-Left-Icon {
  position: absolute;
  top: 16px;
  left: 12px;  
}

.Filter-Dropdown-Text-Search-Text-Clear-Right-Icon  {
  position: absolute;
  padding-top: 12px;  
  left: 11.5rem;
  margin-top: 4px;
  margin-right: 8px;  
}
.Filter-Dropdown-Text-Search-Text-Input-Right-Icon {
  position: absolute;
  top: 0px;
  right: 12px;
}

.Filter-Dropdown-Text-Search-Text-Input-Extra-Padding-Left {
  padding-left: 36px;
}

.Filter-Dropdown-Text-Search-Text-Input-Extra-Padding-Right {
  padding-right: 36px;
}

.Filter-Dropdown-Text-Search-Icon {
  display: inline-block;
  /* margin: 8px 12px 1px; */
  user-select: none;
}

.Filter-Dropdown-Text-Search-Text-Input {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  color: #555667;
  height: 44px;
  border-radius: 3px;
  border: none;
  border: 1px solid #e2e5ea;
  flex-grow: 1;
}

.button {
  margin-left: 5px;
}

.Filter-Dropdown-Text-Search-Text-Input .Text {
  width: 59px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #c6ccd6;
}

.Filter-Dropdown-Text-Search-List-Container {
  -ms-flex-direction: column;
  background-color: white;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  flex-direction: column;
  margin-right: 4px;
  margin-top: 4px;
  max-height: 500px;
  overflow: hidden;
  display: flex;
}

.Filter-Dropdown-Text-Search-List-Items {
  -ms-flex-direction: row;
  color: #555667;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 20px;
}

.Filter-Dropdown-Text-Search-List-Items-Selected {
  border: none;
}

.Filter-Dropdown-Text-Search-No-Match {
  padding-left: 22px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 22px;
  color: #555667;
}

.Float-left {
  float: left;
}

.Float-right {
  float: right;
}

.disable-background-img {
  background-image: none;
}

.Filter-Label {
  text-align: left;
  font-size: 12px;
  color: #2b2d42;
  font-weight: bold;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
