.Header-Bar {
  display: flex;
  background: white;
}

.Column-Header:last-child {
    flex: 1 0 auto;
}

.Grid-Droppable.Collapsed {
  display: none;
}

.Group-Grid {
  border-bottom: 1px solid #e2e5ea;
}

.Group-Grid .Grid-Droppable {
  margin: 0;
}

.Group-Grid:last-child .Grid-Droppable {
  margin-bottom: 10px;
}

.Group-Grid .Cell:last-child {
  box-shadow: inset -1px 0 0 0 #F2F5F7;
}

.Header-Bar.Grouped .Column-Header-No-Hover {
  padding: 0 34px;
  width: auto;
}