.Filter-Load-On-Demand-Container {
  cursor: pointer;
  position: relative;
  outline: none;
  text-align: left;
  padding-right: 6px;
  /* max-width: 140px; */
}

.Filter-Load-On-Demand-Header,
.Filter-Load-On-Demand-Header-Selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  height: 40px;
  min-width: 140px;
  width: 300px;
  text-align: left;
  margin-right: 4px;
}

.Filter-Load-On-Demand-Header {
  border: 1px solid #e2e5ea;
}

.Filter-Load-On-Demand-Header:hover {
  border: solid 1px #8d99ae;
}

.Filter-Load-On-Demand-Header-Selected {
  border: 1px solid #009bde;
}

.Filter-Load-On-Demand-Text {
  -ms-user-select: none;
  color: #555667;
  font-size: 14px;
  margin-left: 12px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

.Filter-Load-On-Demand-Icon {
  margin: 0px 12px;
  user-select: none;
}

.Filter-Load-On-Demand-Holder {
  position: relative;
}

.Filter-Load-On-Demand-Drawer {
  outline: none;
  max-height:680px;
}

.Filter-Load-On-Demand-Text-Input:focus {
  border: 1px solid #009bde;
  outline: none;
}

.Filter-Load-On-Demand-Text-Input::placeholder {
  color: #c6ccd6;
}

.Filter-Load-On-Demand-Text-Input:disabled {
  border: 1px solid #e2e5ea;
  color: #e2e5ea;
  background-color: #f2f5f7;
}

.Filter-Load-On-Demand-Text-Input-Container {
  position: relative;
  display: block;
}

.Filter-Load-On-Demand-Text-Input-Container * {
  box-sizing: border-box;
}

.Filter-Load-On-Demand-Text-Input-Left-Icon {
  position: absolute;
  padding-top: 12px;
  left: 12px;
}

.Filter-Load-On-Demand-Text-Clear-Right-Icon {
  position: absolute;
  padding-top: 12px;
  right: 12px;
}

.Filter-Load-On-Demand-Text-Input {
  border-radius: 0;
  background-color: #ffffff;
  padding: 14px;
  font-size: 14px;
  line-height: 16px;
  color: #555667;
  width: 100%;
  width: -webkit-fill-available;
  border: none;
  border-bottom: 1px solid #e2e5ea;
}

.Filter-Load-On-Demand-Text-Input-Right-Icon {
  position: absolute;
  top: 0;
  right: 12px;
}

.Filter-Load-On-Demand-Text-Input-Extra-Padding-Left {
  padding-left: 36px;
}

.Filter-Load-On-Demand-Text-Input-Extra-Padding-Right {
  padding-right: 36px;
}

.Filter-Load-On-Demand-Text-Input .Text {
  width: 59px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #c6ccd6;
}

.Filter-Load-On-Demand-List-Items-Selected {
  border-bottom: 1px solid #e2e5ea;
}

.List-Items-Selected-Scroll {
  max-height: 150px;
  overflow: auto;
}

.Filter-Load-On-Demand-List-Items {
  border-bottom: 1px solid #e2e5ea;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 4px 0;
}

.Filter-Load-On-Demand-Items-Container {
  -ms-flex-direction: column;
  background-color: white;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  flex-direction: column;
  margin-right: 4px;
  margin-top: 4px;
  min-width: 150px;
  max-width: 350px;
  width: 300px;
  display: flex;
}

.Filter-Load-On-Demand-Scroll {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  background: #fff;
}

.Filter-Load-On-Demand-No-Match {
  font-size: 14px;
  line-height: 16px;
  padding: 8px 22px;
  color: #555667;
}

/* TODO: scroll styling only works in chrome/safari */
.Filter-Load-On-Demand-Items-Container::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: white;
}

.Filter-Load-On-Demand-Items-Container::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

.Filter-Load-On-Demand-Items-Container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8d99ae;
}

.Filter-Label {
  text-align: left;
  font-size: 12px;
  color: #2b2d42;
  font-weight: bold;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Filter-On-Demand-Skeleton {
  padding: 4px 13px;
}
