.Notification {
    display: flex;
    width: 354px;
    border-radius: 3px;
    background-color: #fff;
    font-family: InterUI;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    position: relative;
    color: #2b2d42;
    box-shadow: 0 6px 12px -6px rgba(0, 0, 0, 0.4);
}

.Notification.dark {
    background-color: #555667;
    color: #fff;
}

.Notification-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 36px;
    border-radius: 3px;
}
.Notification-icon.success {
    background-color: #33bb44;
}
.Notification-icon.error {
    background-color: #e53c3c;
}
.Notification-icon.warning {
    background-color: #fbc441;
}
.Notification-icon.info {
    background-color: #009bde;
}

.Notification-content {
    padding: 14px 8px;
    display: flex;
    flex-direction: column;
}

.Notification-description {
    font-weight: normal;
}

.Notification-close {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-left: auto;
    padding: 2px 12px;
    flex-grow: 0;
    align-self: center;
    border-left: 1px solid #555667;
    justify-content: center;
}

.dark .Notification-close {
    border-color: #fff;
}

.Notification-close img {
    cursor: pointer;
}