.CheckboxGroup {
  display: inline-block;
}

.CheckboxGroupItem {
  cursor: pointer;
  padding-top: 6px;
}

.CheckboxGroupItem + .CheckboxGroupItem {
  margin-top: 6px;
}

.CheckboxGroupItem img {
  height: 20px;
}