.Grid-Droppable {
  display: flex;
}

.Hidden-Table-Header .Column-Header,
.Hidden-Table-Header .Column-Header *,
.Hidden-Table-Header .Column-Header-No-Hover,
.Hidden-Table-Header .Column-Header-No-Hover *  {
  /* Setting height to 0px instead of using 'display: none;' to retain column widths. */
  height: 0px;
}