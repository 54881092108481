.Header-Navigation-Item {
  padding-bottom: 1em;
  color: inherit;
  text-decoration: inherit;
  display: flex;
  width: 40%;
  padding-top:1em;
  height:1em;
}

.Header-Navigation-Item:hover {
  cursor: pointer;
  opacity: 0.5;
  background: #f2f5f7;
  border-radius: 4px;
  }

.Header-Navigation-Item-Image {
  padding-right: 0.5em;
}

.Header-Navigation-Item-Text-Primary {
  color: #555667;
  padding-bottom: 0.25em;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

