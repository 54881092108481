.Spinner-Loading {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #009bde;
}

.CircularProgress-root {
  display: inline-block;
  height: 63px !important;
  width: 63px !important;
}

@keyframes CircularProgress-keyframes-circular-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes CircularProgress-keyframes-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.CircularProgress-indeterminate {
  animation: CircularProgress-keyframes-circular-rotate 1.4s linear infinite;
}

.CircularProgress-svg {
  display: block;
}

.CircularProgress-circleIndeterminate {
  animation: CircularProgress-keyframes-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
}

.CircularProgress-circle {
  stroke: currentColor;
}