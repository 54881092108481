.ButtonDatePickerContainer .Modal-Content {
    padding: 0px;
}

.ButtonDatePickerContainer .react-calendar,
.ButtonDatePickerContainer .react-calendar__navigation {
    width: 100%;
    border: none;
}

.ButtonDatePickerContainer .react-calendar {
    padding-bottom: 8px;
}

.ButtonDatePickerContainer .react-calendar__navigation__label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: #2b2d42;
    font-size: 12px;
}

.ButtonDatePickerContainer .react-calendar__month-view__days  {
    margin: 0px;
    padding: 0px 16px;
}

.ButtonDatePickerContainer .react-calendar__month-view__weekdays {
    padding: 0px 16px;
    margin-bottom: 4px;
}

.ButtonDatePickerContainer .react-calendar__month-view__weekdays__weekday {
    color: #8d99ae;
    font-size: 11px;
    font-weight: 600;
}

.ButtonDatePickerContainer .react-calendar__month-view__days__day {
    color: #2b2d42;
    font-size: 14px;
    font-weight: 300;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonDatePickerContainer .react-calendar__tile--active {
    background-color: #009BDE;
    color: #ffffff;
}

.ButtonDatePickerContainer .react-calendar__navigation__prev-button {
    margin-left: 36px;
    margin-top: 0px;
}

.ButtonDatePickerContainer .react-calendar__navigation__next-button {
    margin-right: 36px;
    margin-top: 0px;
}

.ButtonDatePickerContainer .react-calendar__navigation__arrow {
    background-position: center;
}

.ButtonDatePickerContainer .react-calendar__navigation__arrow:focus {
    outline: none;
}

.ButtonDatePickerContainer .react-calendar__month-view__days__day--neighboringMonth {
    color: #8D99AE;
}

.ButtonDatePickerContainer .react-calendar__month-view__days__day:disabled,
.ButtonDatePickerContainer .react-calendar__month-view__days__day--neightboringMonth:disabled {
    color: #E2E5EA;
}