.Help {
  position: relative;
  margin-right: 20px;
}

.Help-Options {
  position: absolute;
  top: 25px;
  right: 0;
  min-width: 200px;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
  padding: 14px 14px 16px 14px;
}

.Help-Options-Link,
.Help-Options-Link:active,
.Help-Options-Link:hover,
.Help-Options-Link:link,
.Help-Options-Link:visited {
  display: flex;
  text-decoration: none;
}

.Help-Options-Link-Text {
  color: #555667;
  font-size: 14px;
  line-height: 16px;
  margin-right: 5px;
}