.Header-Nav {
  align-items: center;
  -ms-flex-align: center;
  background-color:#003F66;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
  display: flex;
  display: -ms-flexbox;
  height: 60px;
  width: 100%;
}

.Header-Logo {
  padding: 10px 20px 10px 20px;
  width: 107px;
}

.Header-Icons {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.Header-Icon {
  margin: 0px 10px;
}

.Header-Icon:hover {
  cursor: pointer;
}

.Header-Username {
  color: white;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 300px;
}

.Header-Company-Name {
  margin-top: 5px;
  color: #C6CCD6;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 300px;
  opacity: .5;
  font-family: Inter UI;
  font-weight: 500;
}

.Header-Nav-Items {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.Header-Menu-Items {
  display: -ms-flexbox;
  display: flex;
}

.Header-User-Info {
  margin-right: 24px;
}

.Header-User-Info span{
  display: block;
}

.Header-Legacy-Container .Legacy-Modal-Container {
  left: 0;
  background-color: rgba(43, 45, 66, 0.5);
}

.Header-Legacy-Container .Legacy-Modal-Container-Overlay {
  position: fixed;
  left: 50%;
  top: 80px;
}