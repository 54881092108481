.ChooseListViewItemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  border: 1px solid #E2E5EA;
  border-radius: 4px;
  overflow-x: auto;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
  box-sizing: border-box;
}

.ChooseListViewCheckbox{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.ChooseListViewHeader{
  background-color: #F0F0F0;
  cursor: default;
  height: 32px;
}

.ChooseListViewTable{
  min-width: 100%;
  flex: 0 0 auto;
  width: auto;
  border-collapse: collapse; 
}

.ChooseListViewTable tr{
  vertical-align: middle;
}

.ChooseListViewTable th{
  vertical-align: middle;
  text-align: left; 
  height: 32px;
  padding: 0px;
}

.ChooseListViewTable thead {
  background-color: #F0F0F0;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
  height: 32px;
}

.NoResultsItem {
  cursor: default;
}

.ChooseListViewItem:hover {
  background-color: #A8DAEF;
  color: #555667;
}

.ChooseListViewItem + .ChooseListViewItem {
  border-top: 1px solid #E2E5EA;
}

.ChooseListViewItem img {
  height: 20px;
}

.ChooseListTitle,
.NoResultsItem span {
  font-size: 18px !important;
  margin-left: 15px;
}

.ChooseListAdditionalItems{
  margin-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
  font-size: 14px;
}

.ChooseListAdditionalItemsContainer{
  padding: 6px 20px;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
}

.ChooseListAdditionalItemsColumn{
  font-size: 10px;
  color: #8d99ae;
}