.SiteMapZoomButtonsContainer {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    margin: 10px;
    top: 0px;
    right: 0px;
}

.iconButton {
    background-color: #fff;
    padding: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    width: 32px;
    height: 32px;
}

.ZoomInButton {
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'><path d='M7 4.8V.2a.215.215 0 0 0-.2-.2H5.2a.215.215 0 0 0-.2.2v4.6a.215.215 0 0 1-.2.2H.2a.215.215 0 0 0-.2.2v1.6a.215.215 0 0 0 .2.2h4.6a.215.215 0 0 1 .2.2v4.6a.215.215 0 0 0 .2.2h1.6a.215.215 0 0 0 .2-.2V7.2a.215.215 0 0 1 .2-.2h4.6a.215.215 0 0 0 .2-.2V5.2a.215.215 0 0 0-.2-.2H7.2a.215.215 0 0 1-.2-.2z' fill='%2383888d'/></svg>");
    position: relative;
}

.ZoomInButton::after {
    content: '';
    position: absolute;
    width: 24px;
    left: 3px;
    height: 1px;
    bottom: -1px;
}

.ZoomOutButton {
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 2'><path d='M11.8 2H.2a.215.215 0 0 1-.2-.2V.2A.215.215 0 0 1 .2 0h11.6a.215.215 0 0 1 .2.2v1.6a.215.215 0 0 1-.2.2z' fill='%2383888d'/></svg>");
}

.ClearPinButton {
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M16.37,16.1L11.75,11.47L11.64,11.36L3.27,3L2,4.27L5.18,7.45C5.06,7.95 5,8.46 5,9C5,14.25 12,22 12,22C12,22 13.67,20.15 15.37,17.65L18.73,21L20,19.72M12,6.5A2.5,2.5 0 0,1 14.5,9C14.5,9.73 14.17,10.39 13.67,10.85L17.3,14.5C18.28,12.62 19,10.68 19,9A7,7 0 0,0 12,2C10,2 8.24,2.82 6.96,4.14L10.15,7.33C10.61,6.82 11.26,6.5 12,6.5Z' fill='%2383888d'/></svg>");
}

.iconButton[disabled] {
    opacity: .35;
}

.iconButton[disabled]:hover {
    cursor: default;
}


