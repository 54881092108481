.Account-Settings {
  position: relative;
  margin-right: 20px;
}

.Account-Settings-Button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Account-Settings-Button:focus {
  outline: none;
}

.Account-Settings-Links {
  position: absolute;
  background-color: white;
  border: #e2e5ea solid 1px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  min-width: 160px;
  text-align: left;
  right: 0;
  top: 25px;
}

.Account-Settings-Links li {
  list-style-type: none;
  padding: 12px 18px;
}

.Account-Settings-Links li.Separator-Top {
  border-top: #e2e5ea solid 1px;
}

.Account-Settings-Links a,
.Account-Settings-Links a:active,
.Account-Settings-Links a:hover,
.Account-Settings-Links a:link,
.Account-Settings-Links a:visited {
  color: black;
  text-decoration: none;
  font-size: 14px;
}
