.Cell-Text {
  color: #555667 !important;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  vertical-align: middle;
  line-height: 1.25;
}

.Cell-Text-Wrap {
  color: #555667 !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  vertical-align: top;
  line-height: 1.25;
}

.TrafficLight-Icon {
  display: inline-block;
}

.TrafficLight-Icon svg {
  vertical-align: middle;
}

.TrafficLight-Text {
  display: inline-block;
  vertical-align: middle;
}

.Row-Selection-Cell-Wrap {
  vertical-align: top !important;
}