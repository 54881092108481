.Group-Header {
  background: white;
  height: 16px;
  padding: 16px 12px;
  font-size: 14px;
  border-bottom: 1px solid #e2e5ea;
  border-left: 1px solid #e2e5ea;
}

.Group-Header:hover {
    cursor: pointer;
}

.Group-Header-Label {
  font-weight: bold;
}

.Count-Badge {
  background: #F2F5F7;
  padding: 5px;
  margin-left: 8px;
  font-size: 13px;
  color: #555667;
  border-radius: 3px;
  font-weight: 600px;
}

.Group-Checkbox {
  display: inline;
  padding: 0 12px;
}

.Collapse-Icon {
  display: inline;
}

.Pagination {
  cursor: default;
}

.Pagination-Container {
  display: inline;
}