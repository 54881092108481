
  .Toast-Style-Enter {
  
  
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
 
  .Toast-Style-Leave {
   
      transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  