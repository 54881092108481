.DocumentSelectContainer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    max-width: 100%;
}

.DocumentSelect, .DocumentSelectAdd {
    height: 112px;
    width: 112px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 4px;
    border: 1px solid #e2e5ea;
}

.DocumentSelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DocumentSelectThumbnail {
    display: flex;
    justify-content: center;
    flex: 0 1 100%;
    position: relative;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.DocumentSelectRemove {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 24px;
    width: 24px;
    background-image: url(../icons/close-circle.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.DocumentSelectRemove:focus {
    outline: none;
}

.DocumentSelectEdit {
    height: 18px;
    width: 18px;
    background-image: url(../icons/edit.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    border: none;
}

.DocumentSelectEdit:focus {
    outline: none;
}

.DocumentSelectName {
    flex: 0 0 auto;
    font-size: 12px;
    border-top: 1px solid #e2e5ea;
    background-color: #ffffff;
    padding: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
}

.DocumentSelectNameSpan {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.DocumentSelectAdd {
    background-color: #e9edf0;
    border: 1px dashed #e2e5ea;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    min-width: 112px;
    width: auto;
}

.DocumentSelectAddIcon {
    height: 24px;
    width: 24px;
}

.DocumentSelectAddText {
    font-size: 14px;
    font-weight: 500;
    color: #c5ccd7;
    margin: 4px;
}

.FieldContainer {
    padding: 6px 0px;
    margin-bottom: 8px;
}

.FieldLabel {
    font-size: 14px;
    font-weight: 500;
    color: #2B2D42;
}

.LinkText {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #c5ccd7;
    padding: 4px;
    align-self: flex-end;
}