.Header-Flyout-Container {
  left: 0;
  margin-top: 18px;
  position: absolute;
  width: 100%;
  z-index: 500;
}

.Header-Flyout-Menu {
  background: #f2f5f7;
  box-shadow: 0px 3px 10px -2px black;
}

.Header-Flyout-Title {
  background:#ffffff;
  font-weight: 500;
  font-size:18px;
  width:100%;
  padding-bottom: 19px;
  padding-left: 2.00em;
  padding-top: 16px;
flex:1 1 auto;
}

.Header-Flyout-Group-Container {
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  -ms-flex-pack: distribute;
   overflow-y: auto;
   background:#ffffff;
   width:100%;
}

.Header-Flyout-Menu-Group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 1;
}

.Header-Flyout-Menu-Group-Title {
  color: #555667;
  font-weight: 500;
  padding-left: 2.00em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-decoration: inherit;
  text-transform: uppercase;
}

.Header-Flyout-Content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 0.5em;
  padding-left:2.00em;
  padding-right: 2.75em;
  padding-bottom: 0.75em;
}

.Header-Flyout-Shadow {
  height: 100vh;
  left: 0;
  position: absolute;
  width: 100vw;
}

.Header-Flyout-Shadow-Button {
  background-color: rgba(43, 45, 66, 0.5);
  border: none;
  height: 100%;  
  width: 100%;
}

.Header-Flyout-Spinner {
  padding: 15px;
}

.Header-Flyout-Menu-Show-All-Button {
  color: #009bde;
  font-size: 14px;
  line-height: 16px;
  margin-left:0.5em;
 }

.Header-Flyout-Content-Truncated-Height{
flex-direction:column;
max-height:15.625em;
}

.Header-Flyout-Close{
 display: flex;
 background:#ffffff;
}

.separator {
  width: 1px;
  height: 220px;
  background-color: #e2e5ea;
  display:flex;
  flex-direction: column;
  flex: 1 1;
}