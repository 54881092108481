.AutoPosition {
    display: flex;
    overflow: hidden;
    z-index: 99999;
    position: absolute;
}

.AutoPosition-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99998;
}

.AutoPosition-Hidden-Container {
    position: absolute;
    top: -9999px;
    left: -99999px;
}