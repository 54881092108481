.Text-Input {
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 14px 12px;
  font-size: 14px;
  line-height: 16px;
  color: #555667;
}

.Text-Input:focus {
  border: 1px solid #009bde;
  border-radius: 4px;
  outline: none;
}

.Text-Input::placeholder {
  color: #c6ccd6;
  opacity: 1;
}

.Text-Input:-ms-input-placeholder {
  color: #c6ccd6;
}

.Text-Input:disabled {
  border: 1px solid #e2e5ea;
  color: #e2e5ea;
  background-color: #f2f5f7;
}

.Text-Input-Container {
  position: relative;
}

.Text-Input-Left-Icon {
  position: absolute;
  top: 0px;
  left: 12px;
}

.Text-Input-Right-Icon {
  position: absolute;
  top: 0px;
  right: 12px;
}

.Text-Input-Extra-Padding-Left {
  padding-left: 36px;
}

.Text-Input-Extra-Padding-Right {
  padding-right: 36px;
}

.Text-Input-Error,
.Text-Input-Error:focus {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}