.Filter-Dropdown-Item {
  -ms-flex-direction: row;
  color: #555667;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 20px;
}

.Filter-Dropdown-Item:hover {
  cursor: pointer;
}

.Filter-Dropdown-Item-Icon {
  -ms-flex-order: 1;
  margin-left: 12px;
  margin-right: 8px;
  order: 1;
  flex-shrink: 0;
}

.Filter-Dropdown-Item-Text {
  -ms-flex-order: 2;
  order: 2;
}

.Filter-Text-Clear-All {
  padding-left: 12px;
  color: #009bde;
}
