.SearchBarContainer {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 6px;
  font-size: 14px;
  line-height: 16px;
  color: #555667;
}

.SearchBarContainer:focus-within {
  border: 1px solid #009bde;
}

.SearchBarContainerError {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}

.SearchBarContainerError:focus-within {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}

.SearchBarContainerDisabled {
  background: none;
}

.SearchBarContainer > * {
  margin-right: 8px;
}

.SearchBarInputContainer {
  flex-grow: 1;
  display: flex;
  min-width: 250px;
  align-items: center;
}

.SearchBarInput,
.SearchBarInput:focus,
.SearchBarInput:active {
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  padding: 8px 6px;
  color: #555668;
}

.SearchBarInput:disabled {
  background: none;
}

.SearchBarInput::placeholder {
  color: #c6ccd6;
  opacity: 1;
}

.SearchBarInput:-ms-input-placeholder {
  color: #c6ccd6;
}

.SearchBarClearButton {
  position: relative;
  top: 2px;
  margin-right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.SearchBarInputContainer img {
  height: 24px;
}
