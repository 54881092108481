.Header-Icon {
  margin: 0px 10px;
}

.Header-Icon:hover {
  cursor: pointer;
}

.Header-Flyout-Item,
.Header-Flyout-Item-Clicked {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.Header-Flyout-Item-Container {
  display: inline;
}
