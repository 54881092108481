.Filter-Dropdown-Container {
  cursor: pointer;
  outline: none;
  position: relative;
  padding-right: 6px;
}

.Filter-Dropdown-Header,
.Filter-Dropdown-Header-Selected {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-right: 4px;
  min-width: 140px;
  width: 300px;
  text-align: left;
}

.Filter-Dropdown-Header {
  border: 1px solid #e2e5ea;
}

.Filter-Dropdown-Header:hover {  
  border: solid 1px #8d99ae;
}

.Filter-Dropdown-Header-Selected {
  border: 1px solid #009bde;
}

.Filter-Dropdown-Text {
  -ms-user-select: none;
  color: #555667;
  font-size: 14px;
  margin-left: 12px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  text-align: left;
}

.Filter-Dropdown-Icon {
  padding-right: 12px;
}

.Filter-Dropdown-Drawer {
  min-width: 150px;
  outline: none;
}

.Filter-Dropdown-Items-Container {
  -ms-flex-direction: column;
  background-color: white;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  flex-direction: column;
  margin-right: 4px;
  margin-top: 4px;
  max-height: 500px;
  overflow: auto;
  min-width: 150px;
  max-width: 350px;
  width: 300px;
}

/* TODO: scroll styling only works in chrome/safari */
.Filter-Dropdown-Items-Container::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: white;
}

.Filter-Dropdown-Items-Container::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

.Filter-Dropdown-Items-Container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8d99ae;
}

.Filter-Label {
  text-align: left;
  font-size: 12px;
  color: #2b2d42;
  font-weight: bold;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
