.SwitchButtonBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.SwitchButton:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.SwitchButton:not(:first-child) {
    border-left: none;
}

.SwitchButton:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.SwitchButton {
    padding: 12px 18px;
    text-transform: uppercase;
    font-size: 16px;
    color: #8D99AE;
    box-sizing: border-box;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid #e4e8ec;
    background-color: rgba(0,0,0,0);
}

.SwitchButtonSelected {
    background-color: #009BDE;
    color: #ffffff;
}

.SwitchButtonDisabled {
    background-color: #C6CCD6;
    color: #ffffff;
}