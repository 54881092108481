.RadioGroup {
  display: inline-block;
}

.RadioGroupItem {
  cursor: pointer;
  padding-top: 6px;
  margin-left: 6px;  
}

.RadioGroupItem + .RadioGroupItem {
  margin-top: 6px;
}

.RadioGroupItem img {
  height: 14px;
  width: 14px;
}