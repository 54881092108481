.Primary-Button,
.Primary-Button:focus,
.Primary-Button:active {
  background-color: #009bde;
  color: white;
  border-radius: 4px;
  border-color: transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 12px;
}

.Primary-Button:disabled {
  background-color: #c6ccd6;
}

.Primary-Button:hover {
  cursor: pointer;
}
