.Column-Options-Container {
  height: 100%;
  margin-left: auto;
  min-width: 16px;
  position: relative;
  right: -12px;
}

.Column-Options-Hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  position: absolute;
}

.Column-Options-Hamburger:focus {
  outline: none;
}
