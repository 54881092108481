.Secondary-Button,
.Secondary-Button:focus,
.Secondary-Button:active {
  background-color: white;
  color: #009bde;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 9px 15px;
  border: 1px solid #009bde;
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 0 #f5f5f5;
}

.Secondary-Button:disabled {
  background-color: #c6ccd6;
  color: #009bde;
}

.Secondary-Button:hover {
  cursor: pointer;
  background-color: transparent;
}
