.Pagination {
  display: flex;
  display: -ms-flex;
  margin: 0px 0px 16px 40px;
  flex: 1 0 auto;
  align-items: center;
}

.Pagination-Control {
  display: flex;
  display: -ms-flex;
  margin-left: 28px;
  align-items: center;
}

.Pagination-Left,
.Pagination-Right {
  all: inherit;
  margin: 0px;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.Pagination-Dropdown-Message {
  display: flex;
  display: -ms-flex;
  margin-left: 16px;
  margin-right: 16px;
  align-items: center;
}

.Pagination-Input-Area {
  background: white;
  border-radius: 4px;
  display: flex;
  display: -ms-flex;
  margin-left: 6px;
  margin-right: 6px;
  padding: 4px 12px 4px 8px;
  border: 1px solid #e2e5ea;
}

.Pagination-Left:disabled,
.Pagination-Right:disabled {
  cursor: initial;
  pointer-events: none;
}

.Pagination-Disabled {
  opacity: 0.25;
  filter: alpha(opacity=25);
}

.Pagination-Error-Message {
  display: flex;
  display: -ms-flex;
  margin: 0px 0px 0px 16px;
  color: red;
  font-size: 14px;
}

.Pagination-Page-Resize {
  margin-left: 16px;
  display: flex;
  display: -ms-flex;
  align-items: center;
}

.Pagination-Page-Resize .Dropdown-Container {
  height: inherit;
  width: inherit;
  margin-right: 16px;
  position: relative;
}

.Pagination-Page-Resize .Dropdown-Header,
.Pagination-Page-Resize .Dropdown-Header-Selected {
  height: inherit;
  padding: 4px 2px 4px 2px;
  box-shadow: none;
  border: 1px solid #e2e5ea;
}

.Pagination-Page-Resize .Dropdown-Text {
  height: inherit;
  line-height: inherit;
  margin-left:6px;
  margin-top: 0;
}

.Pagination-Page-Resize .Dropdown-Icon {
  margin-right: 6px;
}

.Pagination-Page-Resize .Dropdown-Items-Container {
  width: inherit;
  margin-top: 8px;
}

.Group-Header .Pagination {
  float: right;
  margin: 0;
}
