.Chromeless-Button,
.Chromeless-Button:focus,
.Chromeless-Button:active {
  border: none;
  background-color: rgba(255,255,255,0);
  outline: none;
  padding: 0;
}

.Chromeless-Button:hover {
  cursor: pointer;
}

.Chromeless-Button::-moz-focus-inner {
  border: none;
}