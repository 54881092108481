.Cell {
  box-shadow: inset -1px 0 0 0 #F2F5F7, inset 0 -1px 0 0 #F2F5F7;
  padding: 16px 12px;
  background-color: white;
  height: 16px;
}

.Cell-TrafficLight {
  text-align: center;
  display: inline-flex;
}

.Cell-Link {
  color: #009bde;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}


.Cell-Link:hover {
  text-decoration: underline;
}

.Selected-Row {
  background-color: #F2FAFD;
  border-top: solid 1px #189CDB;
  border-bottom: solid 1px #189CDB;
  padding-top: 15px;
  padding-bottom: 15px;
}