.FilterDatePicker-Container {
    cursor: pointer;
    outline: none;
    position: relative;
    padding-right: 6px;
}

.FilterDatePicker-Horizontal-Container {
    display: flex;
    justify-content: space-between;
    max-width: 304px;
}

.FilterDatePicker-Container-Half {
    flex: 0 1 100%;
    cursor: pointer;
    outline: none;
    position: relative;
    padding-right: 6px;
    width: 152px;
}

.FilterDatePicker-Left-Panel-LI-Active {
    background-color: #fff;
}

.FilterDatePicker-Left-Panel-UL {
    list-style: none;
}

.FilterDatePicker-Left-Panel-LI {
    padding: 10px;
}

.FilterDatePicker-Left-Panel-LI-Last {
    padding: 10px;
    margin-left: 5px;
}

.FilterDatePicker-Date-Left-Container {
    cursor: pointer;
    background-color: gainsboro;
    flex: 0 0 auto;
}

.FilterDatePicker-Date-Main-Container {
    cursor: pointer;
    outline: none;
    position: relative;
    flex: 0 0 auto;
}

.FilterDatePicker-Container:nth-child(n+2) {
    margin-left: 90px;
}

.FilterDatePicker-Header,
.FilterDatePicker-Header-Selected {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    display: flex;
    height: 40px;
    margin-right: 4px;
    text-align: left;
    width: 300px;
    justify-content: space-between;
    align-items: center;
}

.FilterDatePicker-Header-Selected {
    border: 1px solid #009bde;
}

.FilterDatePicker-Container-Half .FilterDatePicker-Header,
.FilterDatePicker-Container-Half .FilterDatePicker-Header-Selected {
    width: 148px;
    border: 1px solid #e2e5ea;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.FilterDatePicker-Container-Half .Dropdown-Container {
    width: 148px;
}

.FilterDatePicker-Container .Dropdown-Header,
.FilterDatePicker-Container .Dropdown-Header-Selected {
    height: 40px;
    border: 1px solid #e2e5ea;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.FilterDatePicker-Container .Dropdown-Header,
.FilterDatePicker-Container .FilterDatePicker-Header,
.FilterDatePicker-Container-Half .FilterDatePicker-Header {
    border: 1px solid #e2e5ea;
}

.FilterDatePicker-Container .Dropdown-Header:hover,
.FilterDatePicker-Container .FilterDatePicker-Header:hover,
.FilterDatePicker-Container-Half .FilterDatePicker-Header:hover {
    border: solid 1px #8d99ae;
}

.FilterDatePicker-Container .Dropdown-Header-Selected {
    border: 1px solid #009bde;
}

.FilterDatePicker-Container .Dropdown-Header .Dropdown-Text,
.FilterDatePicker-Container .Dropdown-Header-Selected .Dropdown-Text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: initial;
    text-align: left;
}

.FilterDatePicker-Container .Dropdown-Header .Dropdown-Icon,
.FilterDatePicker-Container .Dropdown-Header-Selected .Dropdown-Icon {
    width: auto;
    height: auto;
    margin: 0px 12px;
    user-select: none;
}

.FilterDatePicker-Label {
    -ms-user-select: none;
    color: #555667;
    display: inline-block;
    font-size: 14px;
    height: 16px;
    margin-left: 12px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FilterDatePicker-Label-Vertical {
    display: flex;
    flex-direction: column;
    padding: 2px 12px 0px 12px;
    justify-content: center;
}

.FilterDatePicker-Label-Vertical div {
    font-size: 12px;
    line-height: 14px;
}

.FilterDatePicker-Icon {
    margin: 0px 12px;
    user-select: none;
}

.FilterDatePicker-Drawer {
    display: none;
}

.FilterDatePicker-Drawer-Show {
    height: auto !important;
    min-width: 150px;
    outline: none;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.FilterDatePicker-Drawer-Show.Left {
    left: auto;
    right: 0;
}

.FilterDatePicker-Dropdown-Wide-Content {
    border: 1px solid #C6CCD6;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    margin: 5px 4px 0 0;
}

.FilterDatePicker-Dropdown-Content {
    border: 1px solid #C6CCD6;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 270px;
    background: #FFFFFF;
    margin: 5px 4px 0 0;
}

.FilterDatePicker-Date-Input-Container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #E2E5EA;
}

.FilterDatePicker-Date-Input {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
}

.FilterDatePicker-Free-Text {
    outline-color: #009BDE;
}

.FilterDatePicker-Free-Text-Invalid {
    outline-color: red;
}

/*Overrides to React-Calendar Styling*/
.FilterDatePicker-Date-Input-Container input,
.react-calendar input {
    border: 1px solid #E2E5EA;
    height: 30px;
    border-radius: 3px;
    width: 105px;
    padding: 1px 8px;
}

.react-calendar button {
    border: none;
    cursor: pointer;
    background: none;
}

.react-calendar abbr {
    text-decoration: none;
}

/*Container*/
.react-calendar {
    width: 270px;
}

/*Month-Year Selector*/
.react-calendar .react-calendar__navigation {
    padding-top: 13px;
    padding-bottom: 13px;
}
.react-calendar .react-calendar__navigation__arrow {
    background-repeat: no-repeat;
}

/*Backward Month*/
.react-calendar .react-calendar__navigation__prev-button {
    margin-left: 20px;
    background-image: url('../../icons/arrow-left.svg');
    margin-top: 2px;
}

/*Selected Month*/
.react-calendar .react-calendar__navigation__label {
    color: black;
    font-weight: 500;
    cursor: default;
}

/*Forward Month*/
.react-calendar .react-calendar__navigation__next-button {
    margin-right: 17px;
    background-image: url('../../icons/arrow-right.svg');
    margin-top: 2px;
}

/*Mon-Sun Bar*/
.react-calendar .react-calendar__month-view__weekdays {
    background-color: #F2F5F7;
}

/*Mon-Sun Individual Days*/
.react-calendar .react-calendar__month-view__weekdays__weekday {
    color: #8D99AE;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

/*Days container*/
.react-calendar .react-calendar__month-view__days {
    padding-bottom: 3px;
}

/*Individual month day*/
.react-calendar .react-calendar__month-view__days__day {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
}

/*Date Selection*/
.react-calendar .react-calendar__tile--active {
    background-color: #F2FAFD;
    border-top: 1px solid #009BDE;
    border-bottom: 1px solid #009BDE;
    margin-bottom: -1px;
}

/*Date Selection Start*/
.react-calendar .react-calendar__tile--rangeStart {
    border-left: 1px solid #009BDE;
    border-top: 1px solid #009BDE;
    border-bottom: 1px solid #009BDE;
    margin-bottom: -1px;
}

/*Date Selection Finish*/
.react-calendar .react-calendar__tile--rangeEnd {
    border-right: 1px solid #009BDE;
    border-top: 1px solid #009BDE;
    border-bottom: 1px solid #009BDE;
    margin-bottom: -1px;
}

/*Date Selection Start and Finish*/
.react-calendar .react-calendar__tile--rangeStart,
.react-calendar .react-calendar__tile--rangeEnd {
    border: 1px solid #009BDE;
    color: #FFFFFF;
    background-color: #009BDE;
}

/*Previous or next month spill over*/
.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    color: #C6CCD6;
}

.react-calendar .react-calendar__tile:focus {
    outline: none;
}

.Filter-Label {
    text-align: left;
    font-size: 12px;
    color: #2b2d42;
    font-weight: bold;
    padding-bottom: 4px;
}
