.Action-Link {
  margin-left: 8px;
  border: 1px solid #B5Dff0;
  border-radius: 3px;
  background-color: white;
  color: #199DDB;
  cursor: pointer;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.Action-Link:hover {
  background-color: #F2F5F7;
  border-color: #3FADE1;
  max-width: 100%;
}

.Action-Link:active {
  background-color: #E9EBEF;
  border-color: #3FADE1;
  max-width: unset;
}

.Overflow-Action-Link {
  padding: 6px 12px;
  background: white;
  border: none;
  cursor: pointer;
  color: #199DDB;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
}

.Overflow-Action-Link:hover {
  background-color: #F2F5F7;
}

.Overflow-Action-Link:active {
  background-color: #E9EBEF;
}

.Overflow-Action-Link + .Overflow-Action-Link {
  border-top: 1px solid #F2F5F7 !important;
}

.Delete-Action {
  color: #E33841;
  border-color: #F6C4C5;
}

.Delete-Action:hover {
  background-color: #F2F5F7;
  border-color: #E33841;
  max-width: unset;
}

.Delete-Action:active {
  background-color: #E9EBEF;
  border-color: #E33841;
  max-width: unset;
}

.Action-Link:disabled,
.Overflow-Action-Link:disabled,
.Delete-Action:disabled {
  opacity: 0.5;
  background-color: white;
  border-color: unset;
  cursor: default;
}

.Overlay-Small .Modal-Title-Text {
  font-size: 24px;
}

.Overlay-Small .Primary-Button {
  padding: 0px;
}

.Modal-Message-Container {
  margin: 50px 0px;
}

.Modal-Link-Button {
  background-color: #009bde;
  color: white;
  border-radius: 4px;
  border-color: transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 12px;
  text-decoration: none;
}

.Modal-Delete-Action {
  background-color: #E33841;
}

.No-Actions-Text {
  font-style: italic;
  font-size: 14px;
  color: gray;
}
