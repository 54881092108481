.SiteMapPin {
    position: absolute;
    pointer-events: none;
}

.SiteMapContainer {
    position: relative;
    overflow: hidden;
}

.SiteMapErrorContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}