.ButtonSelectContainer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
}

.ButtonSelectFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ButtonSelectImageContainer, .ButtonSelectTextOption {
    height: 198px;
    width: 198px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    cursor: pointer;
}

.ButtonSelectImageOption:focus, .ButtonSelectTextOption:focus {
    outline: none;
}

.ButtonSelectImageOption {
    position: relative;
    margin-bottom: 16px;
    border: none;
    background-color: transparent;
    padding: 0px;
}

.ButtonSelectImageContainer {
    border-radius: 6px;
    margin: 4px 16px;
    border: 2px solid #e2e5ea;
    position: relative;
    background-position: center;
    background-size: cover;
}

.ButtonSelectFooter {
    margin: 0px 16px 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 196px;
    align-items: flex-start;
}

.ButtonSelectLabel {
    flex: 0 1 100%;
    font-size: 15px;
    color: #8D99AE;
    text-align: initial;
}

.ButtonSelectSelected .ButtonSelectLabel {
    color: #2B2D42;
}

.ButtonSelectHelp {
    flex: 0 0 18px;
    padding-left: 4px;
    cursor: pointer;
}

.ButtonSelectHelp, .ButtonSelectHelp img {
    height: 18px;
}

.ButtonSelectTextOption {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 4px 16px 16px 16px;
    border: 1px solid #e2e5ea;
    position: relative;
}

.ButtonSelectTextOption.ButtonSelectSelected, .ButtonSelectSelected .ButtonSelectImageContainer {
    border: 2px solid #009de4;
}

.ButtonSelectTextOption.ButtonSelectError, .ButtonSelectError .ButtonSelectImageContainer {
    border: 2px solid #DD655C;
}

.ButtonSelectTextOption:disabled, .ButtonSelectImageContainer:disabled {
    cursor: initial;
}

.ButtonSelectTextOption:disabled {
    background: none;
}

.ButtonSelectImageContainer:disabled {
    opacity: 0.6;
}

.ButtonSelectTextOption:disabled.ButtonSelectSelected, .ButtonSelectSelected .ButtonSelectImageContainer:disabled {
    border: 2px solid #C6CCD6;
}

.ButtonSelectedIcon {
    position: absolute;
    top: -12px;
    left: -12px;
    background-color: #009de4;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    background-image: url(../icons/check.svg);
    background-position: center;
    background-size: 18px;
}

.ButtonSelectTextOption:disabled .ButtonSelectedIcon, .ButtonSelectImageContainer:disabled .ButtonSelectedIcon {
    background-color: #C6CCD6;
}

.ButtonSelectTitle {
    padding: 4px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    color: #2B2D42;
}

.ButtonSelectTextOption:disabled .ButtonSelectTitle, .ButtonSelectTextOption:disabled .ButtonSelectHelpText {
    color: #C6CCD6;
}

.ButtonSelectHelpText {
    padding: 4px;
    color: #8D99AE;
    font-size: 15px;
    text-align: center;
}

.ButtonSelectImageOption .__react_component_tooltip {
    transition: none;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.ButtonSelectImageOption .__react_component_tooltip.type-light {
    color: #2B2D42;
}

.ButtonSelectImageOption .__react_component_tooltip.show {
    opacity: 1;
}

.ButtonSelectImageOption .__react_component_tooltip div {
    margin: 8px 0px;
}

@media screen and (max-width: 768px) {
    .ButtonSelectImageContainer, .ButtonSelectTextOption {
        height: 144px;
        width: 144px;
    }

    .ButtonSelectFooter {
        width: 144px;
    }
}