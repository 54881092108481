.Filter-Bar-Container {
  -ms-flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;    
  margin-left: 40px;    
  flex-basis: 50rem;
}

.Filter-Bar-Item {
  margin: 8px 88px 0px 0px;
}

.Icon {
  padding-left:8px
}
